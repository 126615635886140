// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["s544ETgFh"];

const serializationHash = "framer-ilLrI"

const variantClassNames = {s544ETgFh: "framer-v-1fkbpo4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, gfXcCmiZq: title ?? props.gfXcCmiZq ?? "Copy E-mail"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, gfXcCmiZq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "s544ETgFh", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1fkbpo4", className, classNames)} data-framer-name={"Primary"} initial={variant} layoutDependency={layoutDependency} layoutId={"s544ETgFh"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1s2e3to-container"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"vqYbsKBax-container"} name={"Icon"}><Phosphor color={"var(--token-e6fe3c7b-b8e9-46f7-9c0b-a48c3150fbcd, rgb(18, 19, 19)) /* {\"name\":\"black-200\"} */"} height={"100%"} iconSearch={"House"} iconSelection={"ClipboardText"} id={"vqYbsKBax"} layoutId={"vqYbsKBax"} mirrored={false} name={"Icon"} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0NsYXNoIEdyb3Rlc2sgTWVkaXVt", "--framer-font-family": "\"Clash Grotesk Medium\", \"Clash Grotesk Medium Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-line-height": "12px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-e6fe3c7b-b8e9-46f7-9c0b-a48c3150fbcd, rgb(18, 19, 19)))"}}>Copy E-mail</motion.p></React.Fragment>} className={"framer-g9rboe"} data-framer-name={"Text"} fonts={["CUSTOM;Clash Grotesk Medium"]} layoutDependency={layoutDependency} layoutId={"hdKhV2Wwr"} style={{"--extracted-r6o4lv": "var(--token-e6fe3c7b-b8e9-46f7-9c0b-a48c3150fbcd, rgb(18, 19, 19))", "--framer-paragraph-spacing": "16px"}} text={gfXcCmiZq} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-ilLrI[data-border=\"true\"]::after, .framer-ilLrI [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ilLrI.framer-1e2269k, .framer-ilLrI .framer-1e2269k { display: block; }", ".framer-ilLrI.framer-1fkbpo4 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-ilLrI .framer-1s2e3to-container { flex: none; height: 20px; position: relative; width: 20px; }", ".framer-ilLrI .framer-g9rboe { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ilLrI.framer-1fkbpo4 { gap: 0px; } .framer-ilLrI.framer-1fkbpo4 > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-ilLrI.framer-1fkbpo4 > :first-child { margin-left: 0px; } .framer-ilLrI.framer-1fkbpo4 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"gfXcCmiZq":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramertrNI9di3O: React.ComponentType<Props> = withCSS(Component, css, "framer-ilLrI") as typeof Component;
export default FramertrNI9di3O;

FramertrNI9di3O.displayName = "Button";

FramertrNI9di3O.defaultProps = {height: 20, width: 100};

addPropertyControls(FramertrNI9di3O, {gfXcCmiZq: {defaultValue: "Copy E-mail", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramertrNI9di3O, [{family: "Clash Grotesk Medium", url: "https://framerusercontent.com/assets/TaKEPgR5uwaK3CfbiS2TLwA8rM.woff2"}, ...PhosphorFonts])